import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "./components/Loader/Loader";
const LandingPage = React.lazy(() => import("./pages/LandingPage"));

function App() {
    return (
        <BrowserRouter>
            <React.Suspense fallback={<Loader />}>
                <Routes>
                    {/* // Home Route */}
                    <Route index element={<LandingPage />} />
                </Routes>
            </React.Suspense>
        </BrowserRouter>
    );
}

export default App;
