import React from "react";
import "./Loader.scss";

const Loader = () => {
    return (
        <div className='loaderWrapper'>
            <div className='loader'></div>
        </div>
    );
};

export default Loader;
